<template>
  <div class="theall" v-wechat-title="$route.meta.title">
    <div class="topbackclass"></div>
    <div class="centerclass">
      <div class="centerchild">
        <div class="logoclass">
          <img src="../../assets/login_logo.png" alt="" />
        </div>
        <div class="inputmsgclass">
          <input
            v-model="mobile"
            class="shoujihao"
            type="number"
            name=""
            id=""
            placeholder="请输入手机号"
          />
        </div>
        <div class="inputmsgclass inputmsgclass2">
          <input
            v-model="mobile_code"
            class="shoujihao"
            type="number"
            name=""
            id=""
            placeholder="请输入验证码"
          />
          <div
            :style="
              getcode == '获取验证码' ? '' : 'width:2.3rem;color:#909399;'
            "
            @click="cligetcode"
          >
            {{ getcode == "获取验证码" ? getcode : `${getcode}s后可重新重发` }}
          </div>
        </div>
        <div class="inputmsgclass inputmsgclass2">
          <input
            v-model="password1"
            class="shoujihao"
            :type="canShowPass ? 'text' : 'password'"
            name=""
            id="a1"
            placeholder="请输入新密码"
          />
          <van-icon
            @click="canShowPass = !canShowPass"
            style="margin-right: 0.1rem; width: 0.38rem"
            color="#909399"
            size=".4rem"
            :name="canShowPass ? 'eye-o' : 'closed-eye'"
          />
        </div>
        <div class="inputmsgclass inputmsgclass2">
          <input
            v-model="password"
            class="shoujihao"
            :type="canShowPass2 ? 'text' : 'password'"
            name=""
            id="a2"
            placeholder="请确认密码"
          />
          <van-icon
            @click="canShowPass2 = !canShowPass2"
            style="margin-right: 0.1rem; width: 0.38rem"
            color="#909399"
            size=".4rem"
            :name="canShowPass2 ? 'eye-o' : 'closed-eye'"
          />
        </div>

        <div ontouchstart="" @click="go" :class="cantologin ? 'loginclass2' : 'loginclass'">
          确认
        </div>
        <div @click="$router.push('/login')" class="backlogin">返回登录</div>
      </div>
    </div>
    <div class="xieyi">
      登录注册即代表同意<span @click.stop="$router.push('/serviceprotocol')"
        >《萝卜猎手服务协议》</span
      >
    </div>
  </div>
</template>
<script>
// @click="$router.push('/successpass')"
import { Dialog } from "vant";
export default {
  data() {
    return {
      radio: "",
      password1: "",
      password: "",
      mobile_code: "",
      mobile: "",
      num: 60,
      flag: true,
      cantologin: false,
      canShowPass: false,
      canShowPass2: false,
      getcode: "获取验证码", //获取验证码
      intervalID: "-1",
    };
  },
  watch: {
    mobile(newmsg) {
      if (/^1[3-9]\d{9}$/.test(newmsg)) {
        this.cantologin = true;
      } else {
        this.cantologin = false;
      }
    },
  },
  methods: {
    /**
     * 计时器
     */
    timeing() {
      this.getcode = 60;
      this.intervalID = setInterval(() => {
        this.getcode--;

        if (this.getcode < 1) {
          clearInterval(this.intervalID);
          this.getcode = "获取验证码";
        }
      }, 1000);
    },

    /**
     * 停止计时器
     */
    destroyed() {
      clearInterval(this.intervalID);
    },

    /**
     * 获取验证码
     */
    cligetcode() {
      if (this.getcode != "获取验证码") {
        return;
      }
      if (this.mobile == "") {
        Dialog({ message: "请输入正确手机号" });
        return;
      } else if (!/^1[3-9]\d{9}$/.test(this.mobile)) {
        Dialog({ message: "请输入正确手机号" });
        return;
      }
      this.sendcode();
      this.timeing();
    },

    /**
     * 发送验证码
     */
    sendcode() {
      this.gain();
    },
    // 忘记密码
    go() {
      if (!this.cantologin) {
        return;
      }
      if (this.password1 != this.password) {
        Dialog({ message: "两次输入的密码不一致" });
        return;
      }
      let than = this;
      this.$http
        .post("/firm/v1/login/forget", {
          reqType: "login",
          mobile: this.mobile,
          mobile_code: this.mobile_code,
          password: this.password,
        })
        .then((res) => {
          let arr = JSON.parse(res.data);

          if (arr.code === 0) {
            than.$toast.success("修改成功");
            than.$router.push("/successpass");
          }
        })
        .catch((err) => {});
    },
    // 发送验证码
    gain() {
      this.$http
        .post("/firm/v1/login/valid_send", {
          reqType: "login",
          mobile: this.mobile,
          source: "reset",
        })
        .then((res) => {})
        .catch((eer) => {});
    },
  },
};
</script>
<style scoped>
.backlogin {
  font-size: 0.28rem;
  font-weight: 500;
  color: rgba(69, 113, 208, 0.9);
  line-height: 0.48rem;
  text-align: center;
  margin-top: 0.28rem;
}
.loginmethclass {
  font-size: 0.28rem;
  font-weight: 500;
  color: #606266;
  line-height: 0.48rem;
  text-align: center;
  margin-top: 0.28rem;
}
.loginclass {
  font-size: 0.32rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.44rem;
  text-shadow: 0px 0px 0px rgba(249, 137, 53, 0.59);
  padding: 0.26rem 0;
  text-align: center;
  background: linear-gradient(90deg, #f85615 0%, #f98d37 100%);
  box-shadow: 0rem 0.08rem 0.16rem 0rem rgba(249, 137, 53, 0.59);
  border-radius: 0.48rem;
  opacity: 0.6;
  margin-top: 0.5rem;
}
.loginclass2 {
  text-align: center;
  font-size: 0.32rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.44rem;
  text-shadow: 0px 0px 0px #ffceaa;
  padding: 0.26rem 0;
  background: linear-gradient(90deg, #f85615 0%, #f98d37 100%);
  box-shadow: 0rem 0.08rem 0.16rem 0rem #ffceaa;
  border-radius: 0.48rem;
  margin-top: 0.5rem;
}
.loginclass2:active{
  transform: scale(.9);
}
.fangshiclass {
  font-size: 0.28rem;
  font-weight: 400;
  color: rgba(69, 113, 208, 0.9);
  line-height: 0.48rem;
  padding-top: 0.2rem;
  padding-left: 0.08rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inputmsgclass2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inputmsgclass2 div {
  width: 1.6rem;
  font-size: 0.28rem;
  font-weight: 500;
  color: rgba(254, 89, 3, 0.75);
  line-height: 0.48rem;
}
.inputmsgclass2 input {
  flex: 1;
}
.inputmsgclass {
  padding: 0.28rem 0;
  border-bottom-width: 1px;
  border-bottom-color: #efefef;
  border-bottom-style: solid;
}
.shoujihao {
  width: 100%;
  font-size: 0.32rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.48rem;
  border: none;
}
.logoclass {
  text-align: center;
  margin-bottom: 0.58rem;
}
.logoclass img {
  width: 4.34rem;
  margin: 0;
}
.xieyi {
  font-size: 0.28rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.48rem;
  text-align: center;
  margin-top: 1vh;
}
.xieyi span {
  color: #fe5903;
}
.centerclass {
  background-color: #ffffff;
  height: 69vh;
  margin: 0 0.24rem;
  border-radius: 0.3rem;
  margin-top: -15vh;
  padding: 5vh 7vw;
}
.topbackclass {
  height: 30vh;
  background-image: url("../../assets/login_back.png");
  background-size: 100% 100%;
}
.theall {
  background-color: #f6f6f7;
  height: 100%;
}
.theall > p {
  text-align: center;
  margin-top: 0.2rem;
  color: #ef811a;
  font-size: 0.34rem;
  font-weight: 500;
}
.userImage {
  text-align: center;
}
img {
  width: 2.47rem;
  margin-top: 1.39rem;
}
.alldata {
  padding-top: 0.99rem;
  margin: 0 0.48rem;
}
.data {
  border-bottom-color: #ebeef5;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  font-size: 0.32rem;
  padding-top: 0.26rem;
  padding-bottom: 0.34rem;
}
.fx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fx > input {
  width: 3.5rem;
  padding: 0;
}
.fx > p {
  line-height: 1px;
  color: #ef811a;
}
::placeholder {
  color: #c9ced6;
}

.data > input {
  border: none;
}
.loginP {
  color: #ef821a;
  font-size: 0.3rem;
  margin-top: 0.24rem;
}
.lp {
  border: none;
  display: flex;
  justify-content: space-between;
}
button {
  width: 90%;
  color: #ffffff;
  background: linear-gradient(to right, #f55613, #fc9e46);
  margin: 0 5%;
  border: none;
  border-radius: 30px;
  font-size: 20px;
  padding: 0.2rem;
}
.protocol {
  text-align: center;
  font-size: 13px;
  margin-top: 0.3rem;
  color: #c9ced6;
}
.bt {
  margin-top: 0.91rem;
}
</style>
